import React, { Component } from 'react';
import dynamic from "next/dynamic";
import { ErrorBoundary } from "react-error-boundary";

const E500 = dynamic(() => import("@/pages/500"));
const filePath = `ErrorBoundary-${process.env.NEXT_PUBLIC_PROJECT_ID}`;
const processName = 'APP-CONTROLLER';
class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }  


  render() {
    return (
      <ErrorBoundary FallbackComponent={E500} onError={this.logError}>
        {this.props.children}
      </ErrorBoundary>
    )
  }
}


export default AppErrorBoundary;