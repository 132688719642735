import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as mainActions } from "@/bit/reducers/reducers.main-reducer";
import getCookie from "@gcloud-npm/utils.common.get-cookie";
import isWebview from "@gcloud-npm/utils.common.is-webview";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import CustomLink from "@/bit/components/components.custom-link";
import getGaCookie from "@gcloud-npm/utils.common.get-ga-cookie";

const MAIN_TAGGING_INFO = {
  componentName: "OneTrust",
  componentVariation: "all",
  componentProduct: "",
};

export const addStyle = (css) => {
  let head = document.head || document.getElementsByTagName("head")[0],
    style = document.createElement("style");
  head.appendChild(style);
  style.appendChild(document.createTextNode(css));
};

const initOneTrustListener = (type, handleOnChangeConsent) => {
  let retries = 100;

  let interval = setInterval(() => {
    const check = document.querySelector(".onetrust-pc-dark-filter");
    if (check) {
      OneTrust.OnConsentChanged(handleOnChangeConsent);
      // cuando asigne el onchange, tengo que aplicar la primera vez
      if (!type) {
        /// accept
        OneTrust.AllowAll();
      } else {
        if (type === "settings") {
          OneTrust.ToggleInfoDisplay();
        }

        if (type === "only_technical") {
          OneTrust.RejectAll();
        }
      }
      retries = -100;
    } else {
      retries = retries - 1;
    }

    if (retries <= 0) {
      clearInterval(interval);
    }
  }, 100);
};

const generateScriptTag = (properties) => {
  var onetrustScript = document.createElement("script");
  const propertiesKeys = Object.keys(properties);
  propertiesKeys.forEach((key) => {
    const value = properties[key];
    onetrustScript.setAttribute(key, value);
  });
  return onetrustScript;
};

export const choosePropertiesDependsOnDomain = () => {
  const { host } = window.location;
  // por defecto movistar y subdominios de .movistar
  let res = {
    src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    type: "text/javascript",
    charset: "UTF-8",
    "data-language": "es",
    "data-domain-script": "12091309-599f-4163-b414-211e6b47f78f",
  };

  if (host.indexOf(".movistaronline.es") > -1) {
    res = {
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      type: "text/javascript",
      charset: "UTF-8",
      "data-language": "es",
      "data-domain-script": "43f3c462-807a-4dd9-92b3-8ab4474263f2",
    };
  }

  if (host.indexOf(".movistarfusion.es") > -1) {
    res = {
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      type: "text/javascript",
      charset: "UTF-8",
      "data-language": "es",
      "data-domain-script": "fbb652b5-c0fb-4551-935f-56fbea0317b2",
    };
  }

  return res;
};

let ONE_TRUST_LOADED = false;

export const loadOneTrustScript = ({
  callback,
  type,
  handleOnChangeConsent,
}) => {
  if (!ONE_TRUST_LOADED) {
    const properties = choosePropertiesDependsOnDomain();
    const onetrustScript = generateScriptTag(properties);
    // onetrustScript.setAttribute("async", "");
    onetrustScript.addEventListener("load", function (event) {
      // una vez que tengo el script tengo que setear el onchage para enterarme de la ventana config
      initOneTrustListener(type, handleOnChangeConsent);
      if (callback) {
        callback();
      }
    });
    document.body.appendChild(onetrustScript);
  } else {
    try {
      OneTrust.ToggleInfoDisplay();
    } catch (err) {
      console.error(err);
    }
  }
};

export const clickCookies = (props) => Onetrust.clickCookies(props);

class Onetrust extends Component {
  static clickCookies(props) {
    Onetrust.__singletonRef.__clickCookies(props);
  }
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    Onetrust.__singletonRef = this;
  }

  // Guardamos en Context la cookie creada
  sendGaCookieToContext = async () => {
    const { context, setContextData } = this.props;
    const { clientIDs } = context;
    let maxRetries = 20;
    let waitGa = setInterval(() => {
      maxRetries--;
      const cookieGa = getGaCookie();

      if (cookieGa) {
        const newClientIDs = { ...clientIDs, ga: cookieGa };
        const newContext = { ...context, clientIDs: newClientIDs };
        setContextData(newContext);
        maxRetries = 0;
      }
      if (maxRetries <= 0) {
        clearInterval(waitGa);
      }
    }, 50);
  };

  // esto debería funcionar igual
  handleOnChangeConsent = (props) => {
    const { detail } = props;

    let ad_storage = "denied";
    let analytics_storage = "denied";
    if (detail.indexOf("C0002") !== -1) {
      analytics_storage = "granted";
    }
    if (detail.indexOf("C0004") !== -1) {
      ad_storage = "granted";
    }
    gtag("consent", "update", {
      ad_storage: ad_storage,
      analytics_storage: analytics_storage,
      ad_personalization: ad_storage,
      ad_user_data: ad_storage,
    });
    this.sendGaCookieToContext();
  };

  __clickCookies = ({ type, callback, handlerStyles = true }) => {
    const style =
      type == "settings"
        ? ".onetrust-pc-dark-filter{display: inherit} #onetrust-banner-sdk{ display: none !important;}"
        : ".onetrust-pc-dark-filter{display: none !important;}#onetrust-banner-sdk{ display: none !important;}";
    let idBtn =
      type == "settings"
        ? "#onetrust-pc-btn-handler"
        : "#onetrust-accept-btn-handler";
    if (handlerStyles) {
      addStyle(style);
    }

    loadOneTrustScript({
      callback,
      type,
      handleOnChangeConsent: this.handleOnChangeConsent,
    });

    if (type !== "settings") {
      this.sendGaCookieToContext();
    }
  };

  clickConfig = () => {
    this.__clickCookies({
      type: "settings",
      callback: () => {
        this.setState({
          visible: false,
        });
      },
    });
  };

  clickAccept = () => {
    this.__clickCookies({
      type: "",
      callback: () => {
        this.setState({
          visible: false,
        });
      },
    });
  };

  clickAcceptTechnical = () => {
    this.__clickCookies({
      type: "only_technical",
      callback: () => {
        this.setState({
          visible: false,
        });
      },
    });
  };

  setVisibility = ({ display = "none" }) => {
    try {
      document.getElementById("cookies-bar-component").style.display = display;
    } catch (e) {}
  };

  componentDidMount() {
    // si soy webview hago cosas porque ya para web lo tengo
    const forceShowCookies = window.location.href.indexOf("showCookies") > -1;
    const cookiesAcepted = getCookie("OptanonAlertBoxClosed");
    const isProdEnvironment = `${process.env.NEXT_PUBLIC_ENV_NAME}` === "prod";
    const isDevEnvironment = `${process.env.NEXT_PUBLIC_ENV_NAME}` === "dev";
    const isLocalEnvironment =
      `${process.env.NEXT_PUBLIC_ENV_NAME}` === "local";

    if (isWebview() && isProdEnvironment) {
      // analizamos el status
      let retries = 10;
      let waitWB = setInterval(async () => {
        retries--;
        if (window.webviewBridge) {
          retries = -1;
          const status = await window.webviewBridge.getAttStatus();
          const nullCookieValue = status === null;
          // si es nulo y no tenemos cookies aceptadas muestro
          if (nullCookieValue && !cookiesAcepted) {
            this.setVisibility({ display: "block" });
          } else {
            this.setVisibility({ display: "none" });
          }
        }
        if (retries < 0) {
          clearInterval(waitWB);
        }
      }, 100);
    }
    if (forceShowCookies && !isProdEnvironment) {
      this.setVisibility({ display: "block" });
    }
    if (isDevEnvironment || isLocalEnvironment) {
      this.setVisibility({ display: "none" });
    }
  }

  render() {
    const { visible } = this.state;
    if (!visible) {
      return null;
    }
    return (
      <div id="cookies-bar-component" className="oneTrustCookie fullOpacity">
        <div className="ot-container" data-cy="Popup cookies">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12 text-row">
                <div className="title-gap">
                  <TextInfo
                    color="white"
                    size="t7"
                    kind="heading"
                    level={2}
                    text="Antes de seguir navegando"
                    cyData="Popup cookies title"
                  />
                </div>
                <div className="text-content-gap">
                  <TextInfo
                    fontType="light"
                    color="white"
                    size="subtitle2"
                    text="Movistar utiliza cookies propias y de terceros, así como los datos de la conexión del cliente o usuario para conocer el operador de acceso y, en su caso, asociarlo a su condición de cliente Movistar. Estas cookies serán utilizadas con la finalidad de gestionar y recabar información sobre la utilización del portal, mejorar nuestros servicios y mostrarte publicidad personalizada relacionada con tus preferencias en base a un perfil elaborado a partir de tus hábitos y el análisis de tu comportamiento dentro de la web (por ejemplo, secciones visitadas, consultas realizadas o links visitados). Pulsa 'Aceptar todas las cookies' o 'Rechazar cookies opcionales' si solo autorizas las esenciales para el funcionamiento de la web."
                  />
                  <CustomLink
                    href="//www.movistar.es/particulares/centro-de-privacidad?&amp;optionSelected=politica_cookies"
                    taggingInfo={{
                      ...MAIN_TAGGING_INFO,
                      componentContent: "Más información",
                    }}
                    cyData="Popup cookies consultar info"
                    ariaLabel="Consultar más información sobre cookies propias y de terceros"
                  >
                    <TextInfo
                      size="subtitle2"
                      text="Consultar más información sobre cookies propias y de terceros"
                      color="white"
                    />
                  </CustomLink>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 btn-container">
                <div className="actions-container">
                  <CustomLink
                    onClick={this.clickConfig}
                    taggingInfo={{
                      ...MAIN_TAGGING_INFO,
                      componentContent: "Configurar/rechazar cookies",
                    }}
                    cyData="Popup cookies configurar/rechazar"
                    ariaLabel="Popup cookies configurar/rechazar"
                    label="Configurar/rechazar cookies"
                    className="open-config-link"
                  >
                    <TextInfo
                      size="subtitle2"
                      text="Configurar cookies sin aceptar"
                      color="white"
                      decoration="underline"
                    />
                  </CustomLink>
                  <div className="button-gap-container">
                    <Button
                      buttonType="primary"
                      label="Rechazar cookies opcionales"
                      onClick={this.clickAcceptTechnical}
                      taggingInfo={{
                        ...MAIN_TAGGING_INFO,
                        componentContent: "Rechazar cookies opcionales",
                      }}
                      cyData="Popup cookies aceptar solo técnicas"
                    />
                    <Button
                      buttonType="primary"
                      label="Aceptar todas las cookies"
                      onClick={this.clickAccept}
                      taggingInfo={{
                        ...MAIN_TAGGING_INFO,
                        componentContent: "Aceptar cookies",
                      }}
                      cyData="Popup cookies aceptar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`.oneTrustCookie {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  z-index: 49;

  .row {
    margin: 0px;
    padding-bottom: 0.5rem;
  }
  .text-row {
    padding: 5px 0px;
    padding-top: 0.75rem;
    padding-bottom: 0;

    .link-container {
      margin-bottom: 0.5rem;
      margin-top: 1rem;
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
    }
  }

  .btn-container {
    .actions-container {
      display: flex;
      flex-flow: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      width: 100% !important;

      & > :global(button) {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;

        & > :global(span) {
          text-wrap: no-wrap;
        }
      }

      .button-gap-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        flex-flow: column;

        :global(.buttonx) {
          max-height: 2rem;
          min-height: 39px;

          :global(.next-link) {
            width: fit-content;
          }

          :global(span) {
            text-wrap: nowrap;
          }
        }

        :global(button) {
          width: 100%;
        }
      }
    }
  }

  .ot-container {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: auto;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.9);

    .title-gap {
      margin-bottom: 0.7rem;

      :global(.text) {
        line-height: 120% !important;
      }
    }

    .text-content-gap {
      :global(.text) {
        line-height: 120% !important;
        margin-bottom: 0.3rem;
      }
    }
  }

  @media screen and (min-width: 450px) {
    .btn-container {
      margin-top: 1.5rem;

      .actions-container {
        gap: 1.25rem;

        & > :global(button) {
          margin: 0;
          width: 100%;
          margin-top: 0rem;
        }

        .button-gap-container {
          flex-flow: row;
          justify-content: flex-end;

          & > :global(button) {
            width: fit-content;
          }

          :global(.buttonx) {
            padding: 0.73rem;
            min-height: 42px;

            :global(.next-link) {
              width: fit-content;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 640px) {
    .btn-container {
      .actions-container {
        flex-flow: row nowrap;
        justify-content: space-between;

        & > :global(button) {
          width: fit-content;
        }

        .button-gap-container {
          width: fit-content;
        }
      }
    }
  }

  @media screen and (min-width: 764px) {
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0rem;

      .actions-container {
        flex-flow: row wrap;

        height: fit-content;

        & > :global(button) {
          margin-top: 0;
          width: fit-content;
        }

        .button-gap-container {
          width: fit-content;
          gap: 1rem;
          flex-flow: row nowrap;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .btn-container {
      .actions-container {
        justify-content: flex-end;
      }
    }
  }
}
`}</style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  context: state.main.context,
});

const mapDispatchToProps = (dispatch) => ({
  setContextData: (newData) => dispatch(mainActions.setContextData(newData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Onetrust);
