import getColor from '@gcloud-npm/utils.enums.colors';
import { SEGMENT, getNormalizedSegment } from '@gcloud-npm/utils.enums.segments';
import {
  BUTTON_HEIGHT,
  BUTTON_TYPE,
  BUTTON_BORDER_RADIUS,
  BUTTON_FONT_FAMILY,
  BUTTON_OPERATION,
  ICON_POSITION,
} from './enums.js';
  
  
  /**
   * Calcula si el botón tiene que mostrar la animación del Spin o el texto del botón
   * @returns show OR hidden OR fade
   */
  export const setLabelAnimation = (operationProp) => {
    if (operationProp === BUTTON_OPERATION.NO) {
      return 'animation--no';
    }
    if (operationProp === BUTTON_OPERATION.LOADING) {
      return 'animation--loading';
    }
    if (operationProp === BUTTON_OPERATION.SUCCESS) {
      return 'animation--success';
    }
    if (operationProp === BUTTON_OPERATION.FAILURE) {
      return 'animation--failure';
    }
    return 'animation--no';
  };

  /**
   * Calcula la clase CSS refente a la altural del botón
   * @returns normal OR small
   */
export const setHeightButtonCss = (heightProp) => {
    if (heightProp === BUTTON_HEIGHT.NORMAL) {
      return 'height--normal';
    }
    if (heightProp === BUTTON_HEIGHT.SMALL) {
      return 'height--small';
    }
    return 'height--normal';
  };

  /**
   * Calcula la clase CSS refente aL fontFamily del botón
   * @returns normal OR small
   */
  export const setFontFamilyCss = (fontFamilyProp, segment) => {
    if (fontFamilyProp === BUTTON_FONT_FAMILY.BOLD) {
      return `font-family-${segment}--bold`;
    }
    if (fontFamilyProp === BUTTON_FONT_FAMILY.LIGHT) {
      return `font-family-${segment}--light`;
    }
    if (fontFamilyProp === BUTTON_FONT_FAMILY.REGULAR) {
      return `font-family-${segment}--regular`;
    }
    return `font-family-${segment}--regular`;
  };
  /**
   * Calcula la clase CSS refente al tema/tipo del botón, no necesita segmento, ya uqe lo pilla de entorno
   * @returns type-gp--primary or type-gp--secondary or type-gp--danger
   */
  export const getButtonTypeCss = (buttonTypeProp) => {
    // primary by default
    let style ={
      backgroundColor: getColor('blue_a'),
      color: getColor('white'),
      borderColor: getColor('blue_a'),
    }
    if(buttonTypeProp === BUTTON_TYPE.CUSTO) {
      style={
        backgroundColor: getColor('green_custo'),
        color: getColor('white'),
        borderColor: getColor('green_custo'),
      }
    }
    if(buttonTypeProp === BUTTON_TYPE.CUSTO_REVERSE) {
      style={
        backgroundColor: getColor('white'),
        color: getColor('green_custo'),
        borderColor: getColor('green_custo'),
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.PRIMARY_REVERSE) {
      style={
        backgroundColor: getColor('white'),
        color: getColor('blue_a'),
        borderColor: getColor('blue_a'),
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.SECONDARY) {
      style = {
        backgroundColor: getColor('white'),
        color: getColor('blue_a'),
        borderColor: getColor('blue_a')
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.SECONDARY_REVERSE) {
      style = {
        backgroundColor: getColor('transparent'),
        color: getColor('white'),
        borderColor: getColor('white'),
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.DANGER) {
      style = {
        backgroundColor: getColor('red'),
        color: getColor('white'),
        borderColor: getColor('red'),
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.LINK){
      style = {
        backgroundColor: getColor('white'),
        color: getColor('blue_a'),
        borderColor: getColor('white'),
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.LINK_REVERSE){
      style = {
        backgroundColor: getColor('transparent'),
        color: getColor('white'),
        borderColor: getColor('transparent'),
      }
    }
    
    if (buttonTypeProp === BUTTON_TYPE.TAB){
      style = {
        backgroundColor: getColor('transparent'),
        color: getColor('grey5'),        
        borderRadius: "0",
        border: "none",
        // borderBottom : "1px solid",
        // borderColor: getColor('grey3'),      
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.TAB_ACTIVE){
      style = {
        backgroundColor: getColor('transparent'),
        color: getColor('grey6'),        
        borderRadius: "0",
        border: "none",        
        borderBottom : "2px solid",
        borderColor: getColor('blue_a'),       
      }
    }
    if (buttonTypeProp === BUTTON_TYPE.COLLAPSED){
      style = {
        backgroundColor: getColor('transparent'),
        color: getColor('blue_a_dark'),
        borderColor: getColor('transparent'),
      }
    }

    return style;
  };
  /**
   * Calcula la clase CSS refente al borderRadius del botón
   * @returns border-radius--none OR border-radius--small OR border-radius--big_shadow
   */
  export const setButtonBorderRadiusCss = (buttonBorderRadiusProp) => {
    if (buttonBorderRadiusProp === BUTTON_BORDER_RADIUS.NONE) {
      return 'border-radius--none';
    }
    if (buttonBorderRadiusProp === BUTTON_BORDER_RADIUS.SMALL) {
      return 'border-radius--small';
    }
    if (buttonBorderRadiusProp === BUTTON_BORDER_RADIUS.MEDIUM) {
      return 'border-radius--medium';
    }
    if (buttonBorderRadiusProp === BUTTON_BORDER_RADIUS.BIG_SHADOW) {
      return 'border-radius--bigWithShadow';
    }
    if (buttonBorderRadiusProp === BUTTON_BORDER_RADIUS.BIG) {
      return 'border-radius--big';
    }
    if (buttonBorderRadiusProp === BUTTON_BORDER_RADIUS.FULL) {
      return 'border-radius--full';
    }
    return 'border-radius--small';
  };
  /**
   * Calcula la clase CSS refente a la posición que debe tomar el icono
   * @returns border-radius--none OR border-radius--small OR border-radius--big_shadow
   */
  export const setIconPositionCss = (iconPositionProp) => {
    if (iconPositionProp === ICON_POSITION.LEFT) {
      return 'icon--left';
    }
    if (iconPositionProp === ICON_POSITION.RIGHT) {
      return 'icon--right';
    }
    return '';
  };
  /**
   * Calcula la clase CSS refente al width que modifica el ancho por defecto del botón.
   * @returns
   */
  export const setWidthButtonCss = (buttonWidthProp) => {
    return `width--${buttonWidthProp}`
  };


  export const composeCssClassNamesAndStyles = ({
    segment,
    height,
    fontFamily,
    pill,
    type,
    width,
    disabled,
    className,
    cmsHandler
  }) => {
    const segmentNormalized = getNormalizedSegment(segment);
    const heightCss = setHeightButtonCss(height);
    const fontFamilyCss = setFontFamilyCss(fontFamily, segmentNormalized);
    const widthCss = setWidthButtonCss(width);
    const statusCss = disabled ? 'status--disabled' : '';
    const pillShapeCss = pill ? 'pill-shape' : '';
    const elementEditableCss = cmsHandler ? 'element-editable' : '';
    const customClassName = className ? `${className} ` : '';
    const cssClassNames = `buttonx ${customClassName} button-type--${type} ${fontFamilyCss} ${widthCss} ${heightCss} ${statusCss} ${pillShapeCss} ${elementEditableCss}`
    // styles
    const buttonStyles = getButtonTypeCss(type);
        
    return {
      cssClassNames,
      buttonStyles,
    }
  }