/* eslint-disable no-plusplus */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { generateOnHandlerMethods, extendsAccesibility } from "./utils";

const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;

const appendHost = (href = '') => {
  if (href.indexOf("http") === -1 && href.indexOf("//") === -1) {
    if (currentEnv === "dev" || currentEnv === "local") {
      href = `http://127.0.0.1:8089${href}`;
    }
    if (currentEnv === "code") {
      href = `https://dev.ssr.col.movistar.es${href}`;
    }
    if (currentEnv === "qa_ssr") {
      href = `https://www.movistar.es/qa${href}`;
    }
    if (currentEnv === "dev_ssr") {
      href = `https://www.movistar.es/develop${href}`;
    }

    if (currentEnv === "prod") {
      href = `https://www.movistar.es${href}`;
    }
  }

  if (
    href &&
    href.indexOf("#") === -1 &&
    href.indexOf("?") === -1 &&
    href.indexOf(".") === -1 &&
    href[href.length - 1] !== "/"
  ) {
    href = `${href}/`;
  }
  return href;
};

const getReadyLinkForDev = (href) => {
  let devLink = href;
  const projectBasePath = `${process.env.NEXT_PUBLIC_PROJECT_BASE_PATH}`;
  // eliminamos el basename del proyecto
  if (
    projectBasePath !== "/" &&
    href.indexOf("http") === -1 &&
    href.indexOf("//") === -1
  ) {
    devLink = href.replace(`${process.env.NEXT_PUBLIC_PROJECT_BASE_PATH}`, "");
  }

  if (!devLink) {
    devLink = "/";
  }

  if (
    devLink &&
    devLink.indexOf("#") === -1 &&
    devLink.indexOf("?") === -1 &&
    devLink.indexOf(".") === -1 &&
    devLink[devLink.length - 1] !== "/"
  ) {
    devLink = `${devLink}/`;
  }

  return devLink;
};

const checkIfHasHrefOperators = (href) => {
  let result = false;
  const OPERATORS = ["tel:", "mailto:"];
  OPERATORS.forEach((item) => {
    if (!result && href && href.indexOf(item) > -1) {
      result = true;
    }
  });
  return result;
};

/**
 * Componente que permite hacer cualquier elemento un componente clickable.
 */
const NextLink = (props) => {
  const {
    id,
    children,
    className,
    internal,
    anchor,
    taggingInfo,
    title: cmpTitle,
    target,
    ariaLabel: cmpAriaLabel,
    screenReadable: cmpScreenRedable,
    cms,
    cmsHandler = null,
    style,
    onClick,
    buttonParent,
    buttonTypeHtml,
    disabled,
    cyData,
  } = props;
  let { href } = props;

  let currentClassName = `next-link${className ? ` ${className}` : ""}`;
  const finalCssName = !children
    ? `${currentClassName} nextLinkOverBox`
    : currentClassName;

  const { title, ariaLabel, ariaHidden } = extendsAccesibility({
    cmpTitle,
    cmpAriaLabel,
    cmpScreenRedable,
    taggingInfo,
  });
  /* wrapeamos funciones ejecutadoras */

  if (!onClick && !href && anchor) {
    onClick = () => {
      try {
        const element = document.querySelector(anchor);
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const {
    newOnClick: onClickHandler,
    newOnKeyDown: onKeyDownHandler,
    newOnMouseDown: onMouseDownHandler,
  } = generateOnHandlerMethods({
    onClick,
    taggingInfo,
    disabled,
  });

  // personalizo
  if (cmsHandler) {
    currentClassName = `${currentClassName} element-editable`;
    if (children) {
      return (
        <div
          className={currentClassName}
          onClick={cms && cmsHandler ?
            () => cmsHandler({
              ...props,
              name: 'custom-link'
            }) : null}
        >
          {children}
          <style jsx>{`.nextLinkOverBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
`}</style>
        </div>
      );
    }
    return (
      <div
        className={currentClassName}
        onClick={cms && cmsHandler ?
          () => cmsHandler({
            ...props,
            name: 'custom-link'
          }) : null}
      >
        Enlace
        {/* INJECT_STYLED_JSX_TAG */}
      </div>
    );
  }

  // 0) vemos si tiene href sino tiene solo es un elemento para taggear (TODO)
  if ((!href && onClick) || buttonTypeHtml === "submit") {
    return (
      <button
        id={id || null}
        type={buttonTypeHtml}
        className={finalCssName}
        tabIndex={0}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        title={title ? `Enlace a ${title}` : ''}
        onClick={onClickHandler}
        onKeyDown={onKeyDownHandler}
        onMouseDown={onMouseDownHandler}
        disabled={disabled}
        style={style}
        data-cy={cyData}
      >
        {buttonParent && <div className="hover-layer" />}
        {children}
        <style jsx global>{`.nextLinkOverBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
`}</style>
      </button>
    );
  }

  const hasOperators = checkIfHasHrefOperators(href);
  // "2 ) logica de link as usual"
  if (!internal && (currentEnv === "dev") && !hasOperators) {
    href = getReadyLinkForDev(href);
  }

  if (!internal && !hasOperators && href) {
    href = appendHost(href);
  }

  return (
    <a
      id={id || null}
      className={finalCssName}
      tabIndex={0}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      title={title ? `Enlace a ${title}` : ''}
      href={href}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : ""}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      onMouseDown={onMouseDownHandler}
      disabled={disabled}
      style={style}
      data-cy={cyData}
    >
      {buttonParent && <div className="hover-layer" />}
      {children}
      {/* INJECT_STYLED_JSX_TAG_GLOBAL */}
    </a>
  );
};

NextLink.propTypes = {
  /**
   * {
   *  "info": "Id del componente",
   *  "kind": "dev",
   *  "beautifulName": "Id"
   * }
   */
  id: PropTypes.string,
  /**
   * {
   *  "info": "Url a la que se accederá tras hacer click en el elemento",
   *  "kind": "both",
   *  "beautifulName": "Enlace"
   * }
   */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * {
   *  "info": "Función js que se ejecutará al hacer click",
   *  "kind": "dev",
   *  "beautifulName": "onClick"
   * }
   */
  onClick: PropTypes.func,
  /**
   * {
   *  "info": "Classnames que heredará el componente, sirve para aplicar estilos",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
  /**
   * {
   *  "info": "Información para el tagueado del componente",
   *  "kind": "dev",
   *  "beautifulName": "Información de tagueado"
   * }
   */
  taggingInfo: PropTypes.object.isRequired,
  /**
   * {
   *  "info": "Lugar donde se abrirá el enlace",
   *  "kind": "dev",
   *  "beautifulName": "Href target"
   * }
   */
  target: PropTypes.string,
  /**
   * {
   *  "info": "Texto que se muestra al pasar el ratón por encima del componente. Primero utiliza taggingInfo.componentContent y si es nulo utiliza este atributo",
   *  "kind": "both",
   *  "beautifulName": "Title"
   * }
   */
  title: PropTypes.string,
  /**
   * {
   *  "info": "Proporciona una descripción precisa y concisa del contenido del elemento para usuarios de tecnologías de asistencia, como lectores de pantalla, que no pueden ver o interpretar el contenido visual. Primero utiliza taggingInfo.componentContent y si es nulo utiliza este atributo",
   *  "kind": "dev",
   *  "beautifulName": "AriaLabel"
   * }
   */
  ariaLabel: PropTypes.string,
  /**
   * {
   *  "info": "Deshabilita el componente (solo en ocasiones que se utiliza onClick)",
   *  "kind": "dev",
   *  "beautifulName": "Deshabilitado"
   * }
   */
  disabled: PropTypes.bool,
  /**
   * {
   *  "info": "Cuando se establece en true, indica que el elemento no debe ser presentado visualmente o leído por el lector de pantalla",
   *  "kind": "dev",
   *  "beautifulName": "Visible solo para lectores de pantalla"
   * }
   */
  screenReadable: PropTypes.bool,
  /**
   * {
   *  "info": "Valor del type que cobrará el button en el DOM",
   *  "kind": "dev",
   *  "beautifulName": "Type valor Dom"
   * }
   */
  buttonTypeHtml: PropTypes.string,
  /**
   * {
   *  "info": "Parametriza si el customlink envuelve un button o no",
   *  "kind": "dev",
   *  "beautifulName": "Button es componente padre"
   * }
   */
  buttonParent: PropTypes.bool,
  /**
   * {
   *  "info": "Estilos js para componente button",
   *  "kind": "dev",
   *  "beautifulName": "Estilos"
   * }
   */
  style: PropTypes.object,
  /**
   * {
   *  "info": "Cadena que identifica inequívocamente el componente, para finalidades de testing",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  cyData: PropTypes.string,
  /**
   * {
   *  "info": "Marca si un enlace es interno, por defecto siempre serán externos",
   *  "kind": "dev",
   *  "beautifulName": "Enlace externo"
   * }
   */
  internal: PropTypes.bool,
  /**
   * {
   *  "info": "Selector de ancla para scroll suave hacia un elemento. Se hará sobre esta misma pagina, recuerde si implementa un Enlace no deberias añadir un ancla",
   *  "kind": "both",
   *  "beautifulName": "Ancla"
   * }
   */
  anchor: PropTypes.string,
  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
};

NextLink.defaultProps = {
  id: null,
  className: "",
  target: "_self",
  screenReadable: true,
  disabled: false,
  onClick: null,
  buttonTypeHtml: "button",
  buttonParent: false,
  style: {},
  cyData: "",
  internal: false,
  cms: null,
  cmsHandler: null
};

export default NextLink;
