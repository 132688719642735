import React from "react";
export const SPIN_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DANGER: "danger",
  CUSTO: "custo",
  CUSTO_REVERSE: "custo_reverse",
};
/**
 * Calcula el color del spin
 * @returns primary OR secondary or danger
 */
const setColorOfSpin = (spinType) => {
  if (spinType === SPIN_TYPE.PRIMARY || SPIN_TYPE.CUSTO) {
    // TIPAR Y USAR LAS VAR DENTRO DE LOS STYLES EN VEZ DE PONER EL COLOR A FUEGO ---- to do | to do | to do | to do
    return " type--color-movistar--white";
  }
  if (spinType === SPIN_TYPE.SECONDARY) {
    return " type--color-movistar--blue";
  }
  if (spinType === SPIN_TYPE.DANGER) {
    return " type--color-movistar--white";
  }
  if (spinType === SPIN_TYPE.CUSTO_REVERSE) {
    return " type--color-movistar--custo_green";
  }
  return " type--color-movistar--blue";
};
const generateSizes = (size) => {
  switch (size) {
    case "small":
      return {
        height: 18,
        width: 18,
        viewBox: "0 0 36 36",
      };
    default:
      return {
        height: 24,
        width: 24,
        viewBox: "0 0 36 36",
      };
  }
};
const Spin = ({ size, operation, spinType }) => {
  const data = generateSizes(size);
  const spinColor = setColorOfSpin(spinType);
  const ButtonSpinner = {
    success: (
      <svg
        height={data.height}
        width={data.width}
        className={`Spin ${operation}${spinColor}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          className="path circle"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          className="path check"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
        <style jsx>{`.Spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  overflow: visible;
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
  .path.circle {
    -webkit-animation: dash 0.9s linear;
    animation: dash 0.9s linear;
  }
  .path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s linear forwards;
    animation: dash 0.9s 0.35s linear forwards;
  }
  .path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s linear forwards;
    animation: dash-check 0.9s 0.35s linear forwards;
  }
}

.Spin.loading {
  animation: rotating 1s linear infinite;
}

.Spin.failure {
  .path.line {
    stroke-dashoffset: 0;
  }
}

.Spin.type {
  &--color-movistar--white {
    stroke: #fff;
    fill: #fff;
  }
  &--color-movistar--blue {
    stroke: #019df4;
  }
  &--color-movistar--custo_green {
    stroke: #209e91;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes rotating {
  from {
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
`}</style>
      </svg>
    ),
    failure: (
      <svg
        height={data.height}
        width={data.width}
        className={`Spin ${operation}${spinColor}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          className="path circle"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <line
          className="path line"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="34.4"
          y1="37.9"
          x2="95.8"
          y2="92.3"
        />
        <line
          className="path line"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="95.8"
          y1="38"
          x2="34.4"
          y2="92.2"
        />
        {/* INJECT_STYLED_JSX_TAG */}
      </svg>
    ),
    loading: (
      <svg
        version="1.1"
        id="loader-1"
        height={data.height}
        width={data.width}
        className={`Spin ${operation}${spinColor}`}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style={{ enableBackground: "new 0 0 50 50" }}
        xmlSpace="preserve"
      >
        <path
          className="path circle"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        />
        {/* INJECT_STYLED_JSX_TAG */}
      </svg>
    ),
  };
  return ButtonSpinner[operation];
};
export default Spin;
