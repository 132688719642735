
export const BUTTON_HEIGHT = {
  NORMAL: 'normal',
  SMALL: 'small',
};

export const BUTTON_WIDTH = {
  DEFAULT: 'default',
  SHORT: 'short',
  MEDIUM: 'medium',
  DOUBLE: 'double',
  LARGE: 'large',
  LIQUID: 'liquid',
  FULL: 'full',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  PRIMARY_REVERSE: 'primary_reverse',
  SECONDARY: 'secondary',
  SECONDARY_REVERSE: 'secondary_reverse',
  DANGER: 'danger',
  LINK: 'link',
  LINK_REVERSE: 'link_reverse',
  TAB: 'tab',
  CUSTO: 'custo',
  CUSTO_REVERSE: 'custo_reverse',
  TAB_ACTIVE: 'tab_active',
  COLLAPSED: 'collapsed',
};
export const BUTTON_TYPE_HTML = {
  BUTTON: 'button',
  SUBMIT: 'submit',
};

// deberia de elegirse dependiendo de height
export const BUTTON_BORDER_RADIUS = {
  NONE: 'none',
  SMALL: 'small',
  MEDIUM: 'medium',
  FULL: 'full',
  BIG: 'big',
};

// Fuente del interior
export const BUTTON_FONT_FAMILY = {
  REGULAR: 'regular',
  BOLD: 'bold',
  LIGHT: 'light',
};

// opcional
export const BUTTON_OPERATION = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
  NO: 'no',
};

export const BUTTON_TARGET = {
  SELF: '_self',
  BLANK: '_blank',
  PARENT: 'parent',
  TOP: '_top',
};
// GLYPH POSITION
export const ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};